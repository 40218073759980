.split{
    flex-direction: row;
    justify-content: left;
}

.left{
    width: 50%;
    padding: 0 5%;
}

.left h1{
    font-size: 5vw;
    letter-spacing: 1px;
    margin-bottom: 4%;
}

.left button{
    border: 5px solid black;
    background-color: #FFC815;
    font-size: 1.9vw;
    border-radius: 30px;
    width: 26%;
    padding: 10px;
    margin-bottom: 2%;
    box-shadow: inset .313em .313em 0 rgba(0,0,0,0.4), inset -.313em -.313em 0 rgba(255,255,255,0.4), 0 1em 0.625em -.4em rgba(0,0,0,0.8);
}
.left button:hover{
    width: 27%;
    letter-spacing: 1px;
}

#how-link{
    text-decoration: none;
    color: #169F00;
    font-size: 1.7vw;
    letter-spacing: 1px;
    margin-left: 5%;
    position: relative;
}
#how-link::after{
    content: '';
    display: block;
    width: 0;
    height: 3px;
    background-color: #169F00;
    position: absolute;
    bottom: 0;
    left: 50%;
    transition: 0.3s;
}
#how-link:hover::after{
    left: 10%;
    width: 80%;
}

.right{
    width: 40%;
}
.right img{
    width: 75%;
}


.shop-link a{
    text-decoration: none;
    color: white;
    width: 30%;
}

.container{
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 10px;
    height: 27vh;
}

.container h2{
    font-size: 2.5vw;
    width: 50%;
}

.container img{
    width: 60%;
    margin: 0 2.5%;
}



#how{
    margin-top: 5%;
}

#how h1{
    font-size: 5vw;
    margin-left: 5%;
}

#how .container{
    margin: 0 15%;
    border: 3px solid white;
    border-radius: 0;
    width: 27%;
    padding: 20px 0;
    padding-bottom: 100px;
    margin-bottom: 2%;
    letter-spacing: 1px;
    word-spacing: 3px;
}

#how .container h1{
    font-size: 6vw;
    margin: 0;
    margin-left: 5%;
}

#how p{
    width: 70%;
    font-size: 2vw;
    margin: auto;
    margin-top: 2%;
}