@font-face {
  font-family: arcade;
  src: url(../public/fonts/ARCADECLASSIC.TTF);
}


*{
  outline: none;
  border: none;
  transition: 0.3s;
  font-family: arcade;
  cursor: url(../public/images/cursor1.png), auto;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: #1E1E1E;
  color: white;
  background-image: url(../public/images/bg.gif);
  position: relative;
  overflow-x: hidden;
}


#planet1{
  position: fixed;
  top: 5%;
  left: -15%;
  width: 12%;
  animation: move-planets linear;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  animation-delay: 2s;
  z-index: -1;
}

#planet2{
  position: fixed;
  bottom: 7%;
  left: -15%;
  width: 6%;
  animation: move-planets linear;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  animation-delay: 8s;
  z-index: -1;
}

#ship{
  position: fixed;
  top: 40%;
  right: 5%;
  width: 10%;
}

@keyframes move-planets {
  from{
    left: -10%;
  }
  to{
    left: 110%;
  }
}


main{
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
}


.flex{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.row{
  flex-direction: row;
  justify-content: space-evenly;
}