.friend-req{
    margin-top: 3%;
    margin-left: 3%;
}

.friend-req h1{
    font-size: 3vw;
}

.friend-req input, .auth .select {
    width: 80%;
    padding: 15px 12px;
    font-size: 1.5vw;
    border: 8px solid black;
    border-radius: 23px;
    background-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 6%;
}

.friend-req button{
    border: 5px solid black;
    background-color: #FFC815;
    font-size: 1.8vw;
    border-radius: 30px;
    width: 26%;
    padding: 7px;
    margin-bottom: 2%;
    box-shadow: inset .313em .313em 0 rgba(0,0,0,0.4), inset -.313em -.313em 0 rgba(255,255,255,0.4), 0 1em 0.625em -.4em rgba(0,0,0,0.8);
}

.req-container{
    justify-content: left;
    overflow-x: auto;
    width: 100%;
}

.req{
    background-color: rgb(112, 0, 255, 0.17);
    border-radius: 30px;
    width: 30%;
    border: 3px solid white;
    padding: 20px 10px;
    margin-right: 3%;
    margin-bottom: 1%;
}


.req h2{
    font-size: 2.5vw;
    margin: 0;
}

.req img{
    width: 70%;
    margin-top: 5%;
}

.req button{
    background-color: rgb(22, 159, 0, 0.38);
    color: white;
    font-size: 2.3vw;
    padding: 10px 20px;
}
.req button:hover{
    background-color: rgb(22, 159, 0, 0.6);
}

#reject{
    background-color: rgb(255, 0, 0, 0.38);
}

#reject:hover{
    background-color: rgb(255, 0, 0, 0.6);
}
.yay{
    border: 5px solid black;
    background-color: #FFC815;
    font-size: 1.9vw;
    border-radius: 30px;
    width: 190px;
    padding: 10px;
    margin-bottom: 2%;
    box-shadow: inset .313em .313em 0 rgba(0,0,0,0.4), inset -.313em -.313em 0 rgba(255,255,255,0.4), 0 1em 0.625em -.4em rgba(0,0,0,0.8);
}