.game{
    position: relative;
    z-index: 1;
}

.shooter{
    position: absolute;
    top: 40%;
    right: 4%;
    background-image: url(../../public/images/ship.png);
    background-size: cover;
    width: 150px;
    height: 150px;
    z-index: -1;
}

.beam{
    width: 7%;
    height: 5px;
    border-radius: 20px;
    background-color: green;
    position: absolute;
    /* top: 50%; */
    right: 102%;
}

.aliens{
    width: 20%;
    position: absolute;
    top: 21%;
    left: 7%;
    display: flex;
    flex-wrap: wrap;
}
.aliens img{
    width: 30%;
    height: 60px;
    margin: 25px 5px;
    animation: vibrate;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    z-index: -1;
}

.shoot{
    animation: shoot;
    animation-duration: 3s;
    animation-timing-function: linear;
}

.add{
    animation: add_aliens;
    animation-duration: 1s;
    animation-timing-function: linear;
}

@keyframes shoot {
    from {
        right: 11%;
    }
    to{
        right: 82%;
    }
}


@keyframes add_aliens {
    from {
        left: -20%;
    }
    to{
        left: 7%;
    }
}

@keyframes vibrate {
    25%{
        transform: translateY(-10px);
    }
    50%{
        transform: translateY(0);
    }
    75%{
        transform: translateY(10px);
    }
    100%{
        transform: translateY(0);
    }
}



.scores h1{
    font-size: 2.3vw;
    letter-spacing: 1px;
}