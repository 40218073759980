nav{
    width: 97%;
    padding: 5px 10px;
    margin-top: 15px;
}

nav h1{
    font-size: 4.5vw;
    margin-top: 0;
    margin-bottom: 2%;
    margin-left: 2%;
    color: white;
    display: flex;
    align-items: center;
}

nav h1 img{
    width: 8%;
}

nav button{
    border: 5px solid black;
    background-color: #FFC815;
    font-size: 1.8vw;
    border-radius: 30px;
    width: 10%;
    padding: 7px;
    box-shadow: inset .313em .313em 0 rgba(0,0,0,0.4), inset -.313em -.313em 0 rgba(255,255,255,0.4), 0 1em 0.625em -.4em rgba(0,0,0,0.8);
}
nav button:hover{
    width: 10.5%;
    letter-spacing: 1px;
}

nav a{
    text-decoration: none;
    font-size: 2.3vw;
    letter-spacing: 3px;
}
nav a:hover{
    letter-spacing: 4px;
}
nav h1:hover{
    letter-spacing: 2.9px;
}

.red{
    color: #FF0000;
}
.blue{
    color: #0066FF
}
.yellow{
    color: #FEC814;
}



.controls{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
    margin-top: -1%;
    padding-top: 13%;
}

.control{
    margin: auto;
    background-color: rgb(217, 217, 217, 0.4);
    width: 40%;
    border-radius: 10px;
    padding: 10px;
    text-align: center;
    position: relative;
}
.control h1{
    font-size: 3vw;
    text-decoration: underline;
    letter-spacing: 2px;
}

.control ul{
    font-size: 2vw;
    letter-spacing: 1.2px;
    padding: 0;
}
.control li{
    margin-bottom: 1%;
}
.control .red{
    font-size: 2.5vw;
}

.control button{
    position: absolute;
    top: 7%;
    right: 5%;
    font-size: 2vw;
    border: none;
    outline: none;
    width: 7%;
}