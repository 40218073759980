.leaderboard{
    margin-top: 3%;
}

.leaderboard h1{
    text-align: center;
    font-size: 4vw;
    margin-top: 0;
    margin-bottom: 1%;
}

.table{
    height: 58vh;
    overflow-y: auto;
    border: 1px solid white;
    position: relative;
}

.leaderboard table{
    margin: auto;
    width: 90%;
    text-align: center;
    border-spacing: 15px;
}

.leaderboard thead{
    display: block;
    width: 100%;
}
.leaderboard tbody{
    display: block;
    height: 50vh;
    overflow-y: auto;
    margin-top: 10px;
}

.leaderboard th{
    font-size: 2.6vw;
    width: 30%;
}

.leaderboard tr{
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-evenly;
}

.leaderboard td{
    font-size: 2vw;
    text-align: center;
    width: 30%;
    margin: 0.5% 0;
}