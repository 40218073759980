.auth h1{
    font-size: 5vw;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 5%;
    letter-spacing: 5px;
    display: flex;
    align-items: center;
}

.auth h1 img{
    width: 18%;
}

.auth form{
    width: 40%;
    text-align: center;
}
.auth input, .auth .select {
    width: 80%;
    padding: 15px 12px;
    font-size: 1.5vw;
    border: 8px solid black;
    border-radius: 23px;
    background-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 6%;
}

.auth button{
    border: 5px solid black;
    background-color: #FFC815;
    font-size: 1.8vw;
    border-radius: 30px;
    width: 26%;
    padding: 7px;
    margin-bottom: 2%;
    box-shadow: inset .313em .313em 0 rgba(0,0,0,0.4), inset -.313em -.313em 0 rgba(255,255,255,0.4), 0 1em 0.625em -.4em rgba(0,0,0,0.8);
}
.auth button:hover{
    width: 27%;
    letter-spacing: 1px;
}

.auth span{
    font-size: 1.4vw;
}
.auth a{
    text-decoration: none;
    color: #169F00;
}

.auth .error {
    color: red;
    letter-spacing: 0.8px;
    word-spacing: 2.5px;
    font-size: 1.3vw;
    margin-bottom: 5%;
}

.auth .select {
    width: 84%;
    padding: 5px;
    border-radius: 15px;
}
